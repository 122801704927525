import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  Form,
} from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Wahtsappstatus from "../Wahtsappstatus/Wahtsappstatus";
import { useNavigate } from "react-router-dom";

const Resetpin = () => {
  const storedUserId = localStorage.getItem("user_id");

  const [step, setStep] = useState(1); // Step: 1 for OTP, 2 for Set Password
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [passwordold, setPasswordold] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordold, setShowPasswordold] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const Navi = useNavigate();

  // Send OTP Request
  const handleSendOtp = async () => {
    setLoading(true);
    setError("");
    try {
      await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/confirmation/otp",
        {},
        {
          headers: { user_id: storedUserId },
        }
      );
      setError("OTP Send Successfully");
      setStep(2); // Proceed to OTP verification step
    } catch (err) {
      setError("Failed to send OTP. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Verify OTP
  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 4) {
      setError("Please enter a valid 4-digit OTP.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/confirmation/otp/check",
        { otp: otp },
        {
          headers: {
            user_id: storedUserId,
          },
        }
      );

      if (response.data.result === true) {
        setStep(3); // Proceed to set password step
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (err) {
      setError("Error verifying OTP. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Set Password
  const handleSetPassword = async () => {
    if (!password || password.length !== 4 || isNaN(password)) {
      setError("Password must be a 4-digit number.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/transaction/password/reset",
        { password: password, old_password: passwordold },
        {
          headers: { user_id: storedUserId },
        }
      );
      if (response.data.result === true) {
      alert(response.data.message);
      Navi("/Activepass");
      } else {
      // alert(response.data.message);
setError(response.data.message);
      }
    } catch (err) {
      setError("Error setting password. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid myprofile py-5">
      <div className="mb-5">
        {error && <div className="alert alert-danger">{error}</div>}

        {/* {step === 1 && (
          <FormGroup className="d-flex">
            <Button className="ms-3" onClick={handleSendOtp} disabled={loading}>
              {loading ? "Sending OTP..." : "Set Activation Four Digit Number"}
            </Button>
          </FormGroup>
        )} */}

        {/* {step === 2 && (
          <FormGroup>
            <FormControl
              type="text"
              maxLength="4"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))} // Numeric only
            />
            <Button className="mt-3" onClick={handleVerifyOtp} disabled={loading}>
              {loading ? "Verifying OTP..." : "Verify"}
            </Button>
          </FormGroup>
        )} */}

        {/* {step === 3 && ( */}
        <>
          <FormGroup className="mb-2">
            <InputGroup>
              <FormControl
                type={showPasswordold ? "text" : "password"}
                maxLength="4"
                placeholder="Enter old Password"
                value={passwordold}
                onChange={(e) =>
                  setPasswordold(e.target.value.replace(/\D/g, ""))
                } // Numeric only
              />
              <InputGroup.Text
                onClick={() => setShowPasswordold(!showPasswordold)}
              >
                {showPasswordold ? <FaEye /> : <FaEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <InputGroup>
              <FormControl
                type={showPassword ? "text" : "password"}
                maxLength="4"
                placeholder="Enter 4-digit Password"
                value={password}
                onChange={(e) => setPassword(e.target.value.replace(/\D/g, ""))} // Numeric only
              />
              <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <FormControl
              className="mt-2"
              type="password"
              maxLength="4"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) =>
                setConfirmPassword(e.target.value.replace(/\D/g, ""))
              } // Numeric only
            />
          </FormGroup>

          <Button
            className="mt-3"
            onClick={handleSetPassword}
            disabled={loading}
          >
            {loading ? "Setting Password..." : "Reset Password"}
          </Button>
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default Resetpin;
