import React, { useState, useContext, useEffect } from "react";
import "./Home.scss";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { CiCircleRemove } from "react-icons/ci";
import { userContext } from "../../context/Auth";
import moment from "moment";
import { Button, Form, Modal } from "react-bootstrap";

const Home = () => {
  const { user } = useContext(userContext);
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [Upgrade, setUpgrade] = useState(null);
  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    const storedDate = localStorage.getItem("user_upgrade_date");

    if (storedDate && storedDate !== "null") {
      const upgradeTime = moment(storedDate).format("DD - MMM - YYYY");
      setUpgrade(`You have  upgrade next ${upgradeTime}  `);
    } else {
      setUpgrade("Upgrade with in 28 days");
    }
  }, []); 

  // Load data from location.state or fallback to localStorage
  const [data, setData] = useState(
    location.state?.data || JSON.parse(localStorage.getItem("data")) || {}
  );
  const [userStatus, setUserStatus] = useState(
    localStorage.getItem("userStatus") || "Inactive"
  );

  useEffect(() => {
    if (location.state?.data) {
      localStorage.setItem("data", JSON.stringify(location.state.data));
      setData(location.state.data);
    }
  }, [location.state]);

  useEffect(() => {
    if (user?.data?.user_status) {
      localStorage.setItem("userStatus", user.data.user_status);
      setUserStatus(user.data.user_status);
    }
  }, [user]);

  const getReferralId = () => {
    setShowMessage(true);
  };

  const checkStatus = async () => {
    try {
      const response = await axios.get(
        "https://lunarsenterprises.com:3004/mlm/check/status",
        {
          headers: {
            user_id: data.user_id,
            api_key: data.user_api_key,
          },
        }
      );
      navigate("/my_profile", {
        state: { data: data, status: response.data },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const moveToMyTeam = () => {
    navigate("/my_team", { state: { data: data } });
  };

  const moveToIncome = () => {
    navigate("/my_income", {
      state: { data: data, API: data.user_referral_id },
    });
  };

  const moveToRank = () => {
    // Ensuring that userRank is safely passed and data is stored locally
    navigate("/my_rank", {
      state: { data: data, userRank: user?.data?.user_rank || "" },
    });
  };

  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [selectedDistrict, setSelectedDistrict] = useState(""); // Selected district
  const [loading, setLoading] = useState(false); // Loading state
  const [successMessage, setSuccessMessage] = useState(""); // Success feedback
  const [errorMessage, setErrorMessage] = useState(""); // Error feedback

  const [districts, setDistricts] = useState([]);
  // Sample district list
  // useEffect(() => {
  //   const fetchDistricts = async () => {
  //     try {
  //       const response = await axios.post(
  //         "https://lunarsenterprises.com:3004/mlm/whatsapp-group/list",
  //         {} // Replace with your API for fetching districts
  //       );
  //       setDistricts(response.data.data); // Assuming the API returns an array of districts
  //     } catch (error) {
  //       console.error("Error fetching districts:", error);
  //       setErrorMessage("Failed to fetch districts. Please try again later.");
  //     }
  //   };

  //   fetchDistricts();
  // }, []);
  const user_dis = localStorage.getItem("user_dis");

  const handleSubmit = async () => {
    // if (!selectedDistrict) {
    //   alert("Please select a district!");
    //   return;
    // }

    setLoading(true);
    setSuccessMessage("");

    try {
      // Send the selected district to the endpoint
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/district/whatsapp-group",
        { district: user_dis }
      );

      if (response.status === 200 && response.data.result) {
        const groupData = response.data.data[0];
        if (groupData.wg_group) {
          window.open(groupData.wg_group, "_blank"); // Open WhatsApp group link in a new tab
          setSuccessMessage(
            `Successfully joined the ${selectedDistrict} WhatsApp group!`
          );
        } else {
          alert("No WhatsApp group link found for the selected district.");
        }
      } else {
        alert("Failed to join the group. Please try again.");
      }
    } catch (error) {
      console.error("Error joining the WhatsApp group:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid home">
      <div className="home-details">
        {/* {Upgrade !== null ? ( */}
        <div className="profile-linkss">
          <h3 className="social text-capitalize">{Upgrade}</h3>
        </div>
        {/* ) : null} */}
        <div className="active">
          <h4 className="zero-class">
            {userStatus === "active" ? "Active" : "Inactive"}
          </h4>
        </div>
        <div className="social-medias">
          <div className="social socialsd">
            <FaWhatsapp size={30} />
            <button onClick={handleSubmit} style={{ color: "white" }}>
              <span>Join</span> Whatsapp
            </button>
          </div>
        </div>

        {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "white" }}>
              Select Your District
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Choose District</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedDistrict}
                  onChange={(e) => setSelectedDistrict(e.target.value)}
                >
                  <option value="">-- Select District --</option>
                  {districts.map((district, index) => (
                    <option key={district.id} value={district.wg_district}>
                      {district.wg_district}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleSubmit} disabled={loading}>
              {loading ? "Joining..." : "Join Group"}
            </Button>
          </Modal.Footer>
        </Modal> */}

        {/* Success Message */}
        {/* {successMessage && (
          <div className="mt-3 alert alert-success">
            <strong>{successMessage}</strong>
          </div>
        )} */}
        <div className="profile-links">
          <div className="social" onClick={checkStatus}>
            My Profile
          </div>
          <div className="social" onClick={moveToMyTeam}>
            My Team
          </div>
          <div className="social" onClick={moveToIncome}>
            My Income
          </div>
          <div className="social" onClick={moveToRank}>
            My Rank
          </div>
          <NavLink to="/my-products" style={{ cursor: "pointer" }}>
            <div className="social">My Product</div>
          </NavLink>
          <NavLink
            to="#!"
            style={{
              PointerEvent: "none",
              opacity: "0.5",
              cursor: "not-allowed",
            }}
          >
            <div className="social">Today Achievers</div>
          </NavLink>
          <NavLink
            to="#!"
            style={{
              PointerEvent: "none",
              opacity: "0.5",
              cursor: "not-allowed",
            }}
          >
            <div className="social">My Project</div>
          </NavLink>
          <div className="social" onClick={getReferralId}>
            Referral Link
          </div>
        </div>
      </div>

      {showMessage && (
        <div className="success-message">
          <CiCircleRemove
            size={30}
            color="black"
            className="close-icon"
            onClick={() => setShowMessage(false)}
            style={{ position: "absolute", right: "20px", top: "5px" }}
          />
          <h6>
            https://www.springwayhub.com/register/?referral_id=
            {data.user_referral_id}
          </h6>
        </div>
      )}
    </div>
  );
};

export default Home;
