import React, { useEffect, useState } from "react";
import "../ChatScreens/Chatlist.css"; // Import CSS file for styling
import axios from "axios";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import moment from "moment";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Avatar from "../image/avatar.jpg";

import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaPlus } from "react-icons/fa"; // You can use any icon for the back arrow

const ChatList = () => {
  const storedUserId = localStorage.getItem("user_id");

  const [chatsearch, setChatsearch] = useState("");

  useEffect(() => {
    handleChatList(chatsearch);
  }, [chatsearch]);
  const [chatslist, setChatslist] = useState([]);
  const [chatsError, setChatsError] = useState(false);

  const [chatsLoader, setChatsLoader] = useState(false);

  const handleChatList = async () => {
    setChatsLoader(true);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/chat/list",
        {
          
        },

        {
          headers: {
            user_id: storedUserId,
          },
        }
      );

      if (response.data.result === true) {
        setChatslist(response.data.data);
      } else {
        setChatsError({
          type: "fail",
          message: response.data.message,
        });
        setTimeout(() => {
          setChatsError(false);
        }, 3000);
      }
    } catch (error) {
    } finally {
      setChatsLoader(false);
    }
  };

  const Navigationchat = useNavigate();

  const Navi = (c_id, chat) => {
    Navigationchat(`/ChatView/${c_id}`, {
      state: {
        chat_id: c_id,
        c_to: chat.c_to,
        m_to_id: chat.m_to_id,
        image: chat.u_image || Avatar, // Use default avatar if image is missing
        u_first_name: chat.u_first_name || "Unknown", // Use default name if first name is missing
      },
    });
  };
  const handleBackClick = () => {
    Navigationchat("/home");
  };

  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [chatsErroradd, setChatsErroradd] = useState(null);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSearchSubmit = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/chat/create",
        { recepient_id: 1 },
        {
          headers: {
            user_id: storedUserId,
          },
        }
      );

      console.log("Response:", response.data); // Handle success response

      if (response.data.result === true) {
        setChatslist(response.data.data);
        handleChatList();
        setChatsErroradd({
          type: "success",
          message: response.data.message,
        });
      } else {
        setChatsErroradd({
          type: "fail",
          message: response.data.message,
        });
      }

      // Set timeout to clear error message
      setTimeout(() => {
        setChatsErroradd(null);
      }, 3000);
    } catch (error) {
      console.error("Error:", error); // Handle error
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className="chat-list-container container">
      <button className="back-arrow" onClick={handleBackClick}>
        <FaArrowLeft size={24} color="#fff" />
      </button>

      <button className="back-arrow d-flex mt-3 " onClick={handleSearchSubmit}>
        <FaPlus size={24} color="#fff" style={{ marginLeft: "10px" }} />
        <p className="ms-3">Add Admin</p>
      </button>
      {chatsErroradd && (
        <Alert
          className="mt-3 text-black"
          variant={chatsErroradd.type === "fail" ? "success" : " danger"}
        >
          {chatsErroradd.message}
        </Alert>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSearchSubmit}>
            <Form.Group controlId="formSearch">
              <Form.Label>Search Member</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Referral ID"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add Now
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* <div className="search-bar">
        <input
          type="text "
          placeholder="Search"
          value={chatsearch}
          onChange={(e) => setChatsearch(e.target.value)}
        />
      </div> */}
      <div className="chat-list">
        {chatsLoader ? (
          <p>Loading...</p>
        ) : (
          <div>
            {chatslist?.length > 0 ? (
              chatslist?.map((chat) => (
                <div
                  className="chat-item"
                  key={chat.c_id}
                  onClick={() => Navi(chat.c_id, chat)}
                >
                  <img
                    src={
                      chat.u_image
                        ? `https://lunarsenterprises.com:3004/${chat.u_image}`
                        : Avatar
                    }
                    alt={chat.u_first_name || "User Avatar"}
                    className="chat-image"
                  />
                  <div className="chat-details">
                    <div className="chat-header">
                      <div>
                        <span className="chat-name">{chat.u_first_name} </span>

                        {chat.message.map((message) => (
                          <div className="chat-message">
                            <span className="chat-message">
                              {message.m_message.length > 30
                                ? `${message.m_message.slice(0, 20)}...`
                                : message.m_message}
                            </span>
                          </div>
                        ))}
                      </div>
                      {chat.message.map((msg) => (
                        <div className="me-3">
                          <span className="chat-time">
                            {moment(msg.m_time, "HH:mm:ss").format("hh:mm A")}
                          </span>

                          <div>
                            <span className="chat-time">
                              {moment(msg.m_date).format("DD - MM -YYYY")}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {chat.unread_count > 0 && (
                    <div className="chat-unread-count">{chat.unread_count}</div>
                  )}
                </div>
              ))
            ) : (
              <p>Add Admin to chats </p>
            )}
          </div>
        )}

        {chatsError && (
          <Alert
            className="mt-3"
            variant={chatsError.type === true ? " success" : "danger"}
          >
            {chatsError.text}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default ChatList;
