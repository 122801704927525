import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import Incomewallethis from "../Incomewallethis/Incomewallethis";

import "../Myteam/Myteam.scss";
import { FaSearch } from "react-icons/fa";

const Addtransaction = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [submittedData, setSubmittedData] = useState([]);
  const storedUserId = localStorage.getItem("user_id");
  const [userlogin, setUserlogin] = useState(false); // Set default to false for loading state

  // Handle form submission
  const onSubmit = async (data) => {
    setUserlogin(true); // Set loading state

    // Append new data to the list
    setSubmittedData([...submittedData, data]);

    // Set up FormData for file upload
    const formData = new FormData();
    formData.append("utr_no", data.transactionId);
    formData.append("amount", data.amount);
    formData.append("image", data.image[0]); // Get the first file from the FileList

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/add/upi/transaction",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            user_id: storedUserId, // Pass the user_id in headers
          },
        }
      );

      if (response.data === true) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading transaction data:", error);
    } finally {
      setUserlogin(false); // Reset loading state
      reset(); // Reset the form fields
    }
  };

  //-----------

  const [userhistory, setUserhistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // const [userlogin, setUserlogin] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetchUserHistory();
  }, []);

  const fetchUserHistory = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/upi/transaction/list",
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            user_id: storedUserId, // Pass the user_id in headers
          },
        }
      );
      setUserhistory(response.data.list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("Error fetching data. Please try again.");
      console.error("Error fetching user history:", error);
    }
  };

  const Activation = async (ut_id, newStatus) => {
    setUserlogin(ut_id);
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/admin/upi/transaction/approve",
        { ut_id: ut_id, status: newStatus }
      );

      if (response.data.result === true) {
        alert("Approval Successful");
        fetchUserHistory();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error approving user:", error);
    } finally {
      setUserlogin(null);
    }
  };

  const handleImageClick = (imagePath) => {
    setSelectedImage(`https://lunarsenterprises.com:3004/${imagePath}`);
    setShowModal(true);
  };

  // Filter user history based on the query
  const filteredUserHistory = userhistory?.filter(
    (details) =>
      details.u_referal_id.toLowerCase().includes(query) ||
      details.ut_utr_no.toLowerCase().includes(query)
  );

  return (
    <>
      <div className="container-fluid myprofile ">
        <h4 className=" mb-4 form-label text-white ">Transaction ID </h4>

        {/* Form for data entry */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className="form-label ">Transaction ID</label>
            <input
              type="text"
              {...register("transactionId", {
                required: "Transaction ID is required",
              })}
              className="form-control"
              placeholder="Enter transaction ID"
            />
            {errors.transactionId && (
              <p className="text-danger">{errors.transactionId.message}</p>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">Amount</label>
            <input
              type="number"
              {...register("amount", {
                required: "Amount is required",
                min: { value: 1, message: "Amount must be at least 1" },
                validate: (value) =>
                  value > 0 || "Amount must be greater than zero",
              })}
              className="form-control"
              placeholder="Enter amount"
            />
            {errors.amount && (
              <p className="text-danger">{errors.amount.message}</p>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">Upload Image</label>
            <input
              type="file"
              {...register("image", { required: "Image is required" })}
              className="form-control"
              accept="image/*"
            />
            {errors.image && (
              <p className="text-danger">{errors.image.message}</p>
            )}
          </div>

          <Button type="submit" variant="primary" disabled={userlogin}>
            {userlogin ? (
              <>
                <ThreeDots color="#00BFFF" height={20} width={50} />
                Updating...
              </>
            ) : (
              "Add"
            )}
          </Button>
        </form>
        {/* <Incomewallethis/> */}
      </div>

      <div className="myprofileTRANS">
        <div className=" container  ">
          {loading ? (
            <div className=" centreestyle">
              <ThreeDots color="#00BFFF" height={20} width={50} />
            </div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <>
              <div className="search-box mb-3 inputsearch">
                <input
                  id="search"
                  type="text"
                  className="inputsearch"
                  placeholder="Search by referral ID"
                  value={query}
                  onChange={(e) => setQuery(e.target.value.toLowerCase())}
                />
              </div>

              <div className=" myprofileTRANS">
                <Table
                  responsive
                  // bordered
                  // hover
                  size="sm"
                  className="mb-0 myprofileTRANS "
                >
                  <thead className="nowrap">
                    <tr className="myprofileTRANS nowrap">
                      <th className="myprofileTRANS">Sl no</th>
                      <th className="myprofileTRANS">Transaction Id</th>
                      <th className="myprofileTRANS">Amount</th>
                      <th className="myprofileTRANS">Referral Id</th>
                      <th className="myprofileTRANS">Payment slip</th>
                      <th className="myprofileTRANS">Activation Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUserHistory?.length > 0 ? (
                      filteredUserHistory?.map((details, index) => (
                        <tr key={index} className="nowrap">
                          <td className="myprofileTRANS">{index + 1}</td>
                          <td className="myprofileTRANS">
                            {details.ut_utr_no}
                          </td>
                          <td className="myprofileTRANS">
                            {details.ut_amount}
                          </td>
                          <td className="myprofileTRANS">
                            {details.u_referal_id}
                          </td>
                          <td className="myprofileTRANS">
                            <img
                              src={`https://lunarsenterprises.com:3004/${details.ut_payment_slip}`}
                              alt="Payment Slip"
                              style={{
                                cursor: "pointer",
                                width: "50px",
                                height: "50px",
                              }}
                              onClick={() =>
                                handleImageClick(details.ut_payment_slip)
                              }
                            />
                          </td>
                          <td className="text-capitalize myprofileTRANS">
                            {details.ut_status}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          )}

          <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Payment Slip</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Payment Slip"
                  className="img-fluid"
                />
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default Addtransaction;
