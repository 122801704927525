import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Row,
  Col,
  Container,
  Spinner,
  Alert,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import "./Myproduct.scss";
import "../Myproducts/Products.css";
import imagess from "../image/tour-confitti-4e574de7b4.png";
const Product = ({
  product,
  handleQuantityChange,
  quantities,
  handleBuyNow,
}) => (
  <Col xs={6} sm={6} md={4} lg={3} className="mb-4">
    <motion.article
      className="ezy__blog7-post"
      whileHover={{ scale: 1.05 }}
      transition={{ type: "spring", stiffness: 300, damping: 15 }}
    >
      <div className="position-relative" onClick={() => handleBuyNow(product)}>
        <img
          src={`https://lunarsenterprises.com:3004/mlm/${product.p_image}`}
          alt={product.p_name}
          className="ezy-blog7-banner"
        />
      </div>
      <div className="ms-2 p-md-2">
        <p className="mt-1 ezy__blog7-title fontwgt">
          Price : {product.p_price}
        </p>
        <p
          className={`mt-2 ${
            product.p_stock > 5
              ? "text-success"
              : product.p_stock > 0
              ? "text-danger"
              : "text-muted"
          }`}
        >
          {product.p_stock > 0
            ? product.p_stock < 5
              ? `Hurry! Only ${product.p_stock} left`
              : `Available: ${product.p_stock}`
            : "Out of Stock"}
        </p>
        <div className="d-flex align-items-center mt-2">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleQuantityChange(product.p_id, -1)}
            disabled={quantities[product.p_id] <= 1}
          >
            -
          </Button>
          <span className="mx-3">{quantities[product.p_id]}</span>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => handleQuantityChange(product.p_id, 1)}
            disabled={quantities[product.p_id] >= product.p_stock}
          >
            +
          </Button>
        </div>
      </div>
      <div className="buttoncenter mt-3 mb-2">
        <Button
          className="button-product"
          disabled={product.p_stock <= 0}
          onClick={() => handleBuyNow(product)}
        >
          <span className="Enroll">Buy Now</span>
        </Button>
      </div>
    </motion.article>
  </Col>
);

const Myproduct = () => {
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [quantities, setQuantities] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [adjustedQuantity, setAdjustedQuantity] = useState(1);
  const { register, handleSubmit, watch, reset, setValue } = useForm();

  const Username = localStorage.getItem("user_name");
  const referral_id = localStorage.getItem("referral_id");
  const Userid = localStorage.getItem("user_id");
  const handleClose = () => setShowwelcomemsg(false);

  const [showwelcomemsg, setShowwelcomemsg] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3004/mlm/product/list"
        );
        const result = response.data;

        if (result?.result) {
          const products = result.data.flatMap((category) => category.product);
          const initialQuantities = Object.fromEntries(
            products.map((product) => [product.p_id, 1])
          );
          setQuantities(initialQuantities);
          setCategories(result.data);
          setActiveTab(result.data[0]?.c_id.toString());
          setLoading(false);
        } else {
          throw new Error(result?.message || "Failed to fetch data.");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleQuantityChange = (p_id, increment) => {
    setQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[p_id] || 1;
      const newQuantity = currentQuantity + increment;

      // Find the product by its ID to get the stock value
      const product = categories
        .flatMap((category) => category.product)
        .find((p) => p.p_id === p_id);
      const maxStock = product ? product.p_stock : 1;

      // Ensure the quantity doesn't go below 1 and doesn't exceed available stock
      const adjustedQuantity = Math.min(Math.max(newQuantity, 1), maxStock);

      setValue("quantity", adjustedQuantity);
      setAdjustedQuantity(adjustedQuantity);
      console.log(
        `Product ID: ${p_id}, New Quantity: ${adjustedQuantity}, Stock Available: ${maxStock}`
      );

      return {
        ...prevQuantities,
        [p_id]: adjustedQuantity,
      };
    });
  };

  const [Modalorderlist, setModalorderlist] = useState(false); // Start with the modal closed
  const [orderList, setOrderList] = useState([]);

  // Fetch order list data
  const fetchOrderList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/order/list",
        {},

        {
          headers: {
            user_id: Userid,
          },
        }
      );
      setOrderList(response.data.data);
    } catch (error) {
      console.error("Error fetching order list:", error);
    }
  };

  const handleOpen = () => {
    setModalorderlist(true);
    fetchOrderList(); // Fetch the order list when the modal is opened
  };

  const handleCloseo = () => setModalorderlist(false);

  const handleBuyNow = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null); // Clear the selected product
    reset(); // Reset the form fields using react-hook-form's reset function
  };

  const onSubmit = async (data) => {
    console.log("Order Submitted", data);

    try {
      // Calculate the total price
      const totalCalculatedPrice =
        selectedProduct.p_price * data.quantity || adjustedQuantity;

      // Prepare the data payload
      const dataaddon = {
        referral_id: data.referral_id,
        product_id: selectedProduct.p_id,
        address: data.address,
        pincode: data.pincode,
        state: data.state,
        district: data.district,
        panchayat: data.panchayat,
        taluk: data.taluk,
        corporation: data.corporation,
        price: totalCalculatedPrice,
        quantity: data.quantity,
        mobile: data.phone,
      };

      // Make a POST request with the dataaddon object directly
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/order/add",
        dataaddon, // Send dataaddon directly
        {
          headers: {
            user_id: Userid,
          },
        }
      );

      // Handle the response
      if (response.data.result === true) {
        setShowModal(false);
        setShowwelcomemsg(true);
        reset();
      } else {
        Alert("error");
      }
    } catch (error) {
      console.error("Error submitting order", error);
    }
  };

  const quantity = watch("quantity") || 1;
  const totalPrice = selectedProduct
    ? selectedProduct.p_price * adjustedQuantity
    : 0;

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <section className="ezy__epgrid12 gray home">
      <Container>
        <div className="justify-content-end d-flex">
          <Button className="btnorder" onClick={handleOpen}>
            <p>Your Order List</p>
          </Button>

          <Modal show={Modalorderlist} onHide={handleCloseo}>
            <Modal.Header closeButton>
              <Modal.Title>Your Orders</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {orderList?.length > 0 ? (
                <ul className="order-list">
                {orderList?.map((order, index) => (
                  <li key={index} className="order-item">
                    <div className="order-details">
                      <img
                        src={`https://lunarsenterprises.com:3004/mlm/${order.p_image}`}
                        alt="Product"
                        className="product-image me-3"
                      />
                      <div className="order-info">
                        <p>Order ID: {order.o_id}</p>
                        <p>Quantity: {order.o_quantity}</p>
                        <p>Total: ${order.o_amount}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              
              ) : (
                <p>No orders found.</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <Row className=" mt-2">
          <Col lg={9}>
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
              className="mb-3"
            >
              {categories.map((category) => (
                <Tab
                  key={category.c_id}
                  eventKey={category.c_id.toString()}
                  title={category.c_name}
                >
                  <Row>
                    {category.product.map((product) => (
                      <Product
                        key={product.p_id}
                        product={product}
                        handleQuantityChange={handleQuantityChange}
                        quantities={quantities}
                        handleBuyNow={handleBuyNow}
                      />
                    ))}
                  </Row>
                </Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>

      {/* Modal for Buy Now */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="referral">
                <Form.Label>Referral Id</Form.Label>
                <Form.Control
                  type="text"
                  value={` ${referral_id}`} // Combining the two values
                  placeholder="Enter your name"
                  {...register("referral_id", { required: true })}
                  readOnly
                />
              </Form.Group>

              {/* <Form.Group controlId="name" className="mt-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedProduct.p_name}
                  placeholder="Enter your Product Name"
                  {...register("prdt_name", { required: true })}
                  // readOnly
                />
              </Form.Group> */}
              <Form.Group controlId="address" className="mt-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your address"
                  {...register("address", { required: true })}
                />
              </Form.Group>

              <Form.Group controlId="phone" className="mt-3">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Contact Number"
                  {...register("phone", { required: true })}
                />
              </Form.Group>

              <Form.Group controlId="Pincode" className="mt-3">
                <Form.Label>Pincode</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Pincode"
                  {...register("pincode", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="state" className="mt-3">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your state"
                  {...register("state", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="district" className="mt-3">
                <Form.Label>District</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your district"
                  {...register("district")} // No validation rules, making it optional
                />
              </Form.Group>

              <Form.Group controlId="taluk" className="mt-3">
                <Form.Label>Taluk</Form.Label>
                <Form.Control
                  type="text"
                  value={"optional"}
                  placeholder="Enter your taluk"
                  {...register("taluk", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="corporation" className="mt-3">
                <Form.Label>Corporation</Form.Label>
                <Form.Control
                  type="text"
                  value={"optional"}
                  placeholder="Enter your corporation"
                  {...register("corporation", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="panchayat" className="mt-3">
                <Form.Label>Panchayat</Form.Label>
                <Form.Control
                  type="text"
                  value={"optional"}
                  placeholder="Enter your panchayat"
                  {...register("panchayat", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="quantitye" className="mt-3">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="number"
                  value={adjustedQuantity}
                  readOnly
                  {...register("quantity", { required: true })}
                />
              </Form.Group>
              <Form.Group controlId="price" className="mt-3">
                <Form.Label>Total Price</Form.Label>
                <Form.Control
                  type="text"
                  readOnly
                  value={`Rs. ${totalPrice.toFixed(2)}`}
                  {...register("price", { required: true })}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showwelcomemsg} onHide={setShowwelcomemsg} centered>
        <Modal.Body>
          <div className="">
            <div>
              <img src={imagess} className="imgstyle" />
            </div>
            {/* <div className="imagecenter">
              <img src={propic} alt="Profile" className="profile-icon" />
            </div> */}
            <div className="text-center mt-3">
              <h2>Your order has been successfully placed</h2>
              {/* <p>
              Thank you for choosing AIMS Billing. Before you start, we'd love to show you around
              and help you navigate the app.
            </p> */}
              {/* <button onClick={handleClose} className="show-me-btn">close</button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default Myproduct;
