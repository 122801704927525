import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  Form,
} from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Wahtsappstatus from "../Wahtsappstatus/Wahtsappstatus";
import { useNavigate } from "react-router-dom";

const Activepass = () => {
  const storedUserId = localStorage.getItem("user_id");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const Navi = useNavigate();

  // Set Password
  const handleSetPassword = async () => {
    if (!password || password.length !== 4 || isNaN(password)) {
      setError("Password must be a 4-digit number.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/transaction/password/check",
        { password },
        {
          headers: { user_id: storedUserId },
        }
      );

      const { result, message } = response.data;

      if (result === true) {
        // alert(message);
        Navi("/Activation");
      } else {
        setError(message);
      }
    } catch (err) {
      setError("Error setting password. Please try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid myprofile py-5">
      <div className="mb-5">
        {error && <div className="alert alert-danger">{error}</div>}

        <>
          <FormGroup>
            <InputGroup>
              <FormControl
                type={showPassword ? "text" : "password"}
                maxLength="4"
                placeholder="Enter 4-digit Password"
                value={password}
                onChange={(e) => setPassword(e.target.value.replace(/\D/g, ""))} // Numeric only
              />
              <InputGroup.Text onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </InputGroup.Text>
            </InputGroup>
            <div
              class="d-flex justify-content-end mt-1"
              onClick={handleSetPassword}
              disabled={loading}
            >
              <p className="text-white size">
                <a className="text-decoration-none" href="/Resetpin">
                Forgot Password ?</a></p>
            </div>
          </FormGroup>

          <div className="d-flex justify-content-center ">
            <Button
              className="w-100"
              onClick={handleSetPassword}
              disabled={loading}
            >
              {loading ? "Continue..." : "Continue"}
            </Button>
          </div>
        </>
      </div>
    </div>
  );
};

export default Activepass;
