import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Wahtsappstatus/Wahtsappstatus.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import moment from "moment";

const Wahtsappstatus = () => {
  const storedUserId = localStorage.getItem("user_id");
  const [status, setStatus] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading

  useEffect(() => {
    fetchStatus();
  }, []);

  const fetchStatus = async () => {
    try {
      const Roleid = {
        role: "user",
      };

      const response = await axios.post(
        "https://lunarsenterprises.com:3004/mlm/task/list",
        Roleid,
        {
          headers: { user_id: storedUserId },
        }
      );
      setStatus(response.data.list);
    } catch (error) {
      console.error("Error fetching task list:", error);
      setError("Failed to fetch statuses. Please try again.");
    }
  };

  const handleDownload = async (url, filename) => {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "image/jpeg" });
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      link.click();

      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.error("Error downloading image:", error);
      setError("Failed to download the image. Please try again.");
    }
  };

  const handleUpload = async (t_id) => {
    if (!selectedFile) {
      setError("Please select an image to upload.");
      return;
    }

    setLoading(true); // Set loading state to true when starting upload

    const formData = new FormData();
    formData.append("t_id", t_id); // Use dynamic t_id for each status
    formData.append("image", selectedFile);

    try {
      await axios.post(
        "https://lunarsenterprises.com:3004/mlm/user/task/add",
        formData,
        {
          headers: {
            user_id: storedUserId,
          },
        }
      );
      setSelectedFile(null);
      fetchStatus(); // Refresh status list after upload
      setLoading(false); // Set loading state to false when upload finishes
    } catch (error) {
      console.error("Error uploading image:", error);
      setError("Failed to upload image. Please try again.");
      setLoading(false); // Reset loading state if upload fails
    }
  };

  return (
    <div className=" container">
      <div className="upload-section mb-3 mt-2">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setSelectedFile(e.target.files[0])}
        />
      <button
  onClick={() => handleUpload(status[0]?.t_id)}
  disabled={!selectedFile || loading || !status?.[0]?.t_id} // Disable button when no task ID is available
  className="btn mt-3 btn-primary"
>
  {loading ? (
    <div className="spinner-border text-light" role="status">
      <span className="visually-hidden">Uploading...</span>
    </div>
  ) : (
    "Upload"
  )}
</button>

        {error && <p className="text-danger mt-2">{error}</p>}
      </div>

      {status?.length > 0 ? (
        <Swiper
          pagination={{ clickable: true }}
          navigation
          modules={[Pagination, Navigation]}
        >
          {status?.map((task) => (
            <SwiperSlide key={task.t_id}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.8 }}
                className="status-image-container"
                style={{
                  position: "relative",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#000",
                }}
              >
                <img
                  src={`https://lunarsenterprises.com:3004/${task.t_image}`}
                  alt={task.t_date}
                  style={{
                    maxHeight: "250px",
                    maxWidth: "500px",
                    objectFit: "contain",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: "20px",
                    color: "#fff",
                    textAlign: "center",
                    width: "100%",
                    fontSize: "18px",
                  }}
                >
                  {moment(task.t_date).format("MMM-DD-YYYY")}
                </div>
                <button
                  onClick={() =>
                    handleDownload(
                      `https://lunarsenterprises.com:3004/${task.t_image}`,
                      `status-${task.t_id}.jpg`
                    )
                  }
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    padding: "10px",
                    background: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  Download
                </button>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p className="text-center text-muted mt-5">No statuses available</p>
      )}
    </div>
  );
};

export default Wahtsappstatus;
